//import { ConnectKitButton } from "connectkit"

export default function ConnectWalletButton() {
    return null

    /*
    return (
        <ConnectKitButton.Custom>
          {({ isConnected, show, truncatedAddress, ensName }) => {
            return (
              <button className="font-primaryBrand" onClick={show}>
                {isConnected ? ensName ?? truncatedAddress : "Connect Wallet"}
              </button>
            );
          }}
        </ConnectKitButton.Custom>
    );
    */
}
